import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import * as Icon from 'react-feather'

export interface Props {
  discount?: string
  image?: string
  languageCode: string
  title: string
  URL?: string
  validity?: string
}

export const Offer = memo(function Offer({
  discount,
  image,
  languageCode,
  title,
  URL,
  validity,
}: Props) {
  return (
    <Container>
      <Link to={URL ? URL : ''}>
        {image ? (
          <Image
            className="swiper-lazy"
            style={{ backgroundImage: `url(${image})` }}
          />
        ) : null}
        {discount ? (
          <Discount className="offer-discount">
            <DiscountLabel>{discount}</DiscountLabel>
            {useVocabularyData('discount', languageCode)}
          </Discount>
        ) : null}
        <TextWrap className="offer-text-wrap" dial={5}>
          <Info>
            {validity ? (
              <Validity dial={5} row>
                <Icon.Tag />
                {validity}
              </Validity>
            ) : null}
            <Title className="offer-title">{title}</Title>
          </Info>
          {URL ? (
            <CTA
              className="offer-cta"
              label={useVocabularyData('details', languageCode)}
              noActions
            />
          ) : null}
        </TextWrap>
      </Link>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  a {
    display: flex;
    width: 100%;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    &:hover {
      .swiper-lazy {
        transform: scale(1);
      }
      .offer-discount {
        opacity: 0;
      }
      .offer-text-wrap {
        &:before {
          opacity: 1;
          transform: scale(1);
        }
      }
      .offer-cta {
        bottom: 0;
        opacity: 1;
      }
    }
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: 0.3s ease-out;
`

const TextWrap = styled(FlexBox)`
  width: calc(100% - 5rem);
  height: calc(100% - 5rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding: 6rem 1.5rem;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  z-index: 2;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.03);
    transition: 0.3s ease-out;
  }

  @media (max-width: 1199px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Info = styled.div`
  position: relative;
`

const Title = styled.h2`
  display: block;
  display: -webkit-box;
  max-height: 7.5rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: 1199px) {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
`

const Validity = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  letter-spacing: 1px;
  line-height: 0.875rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  svg {
    height: 18px;
    margin-right: 0.375rem;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Discount = styled.span`
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.5625rem;
  letter-spacing: 1px;
  line-height: 0.6875rem;
  padding: 0.8125rem 1.125rem 1rem;
  position: absolute;
  top: 0;
  left: 50%;
  text-transform: uppercase;
  transform: translateX(-50%);
  transition: 0.3s ease-out;
  z-index: 2;

  @media (max-width: 1023px) {
    display: none;
  }
`

const DiscountLabel = styled.div`
  font-size: 1.1875rem;
  font-weight: 700;
  letter-spacing: initial;
  line-height: 1.4375rem;
`

const CTA = styled(Button)`
  position: absolute;
  bottom: -1rem;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  transition: 0.3s ease-out;

  @media (max-width: 1023px) {
    opacity: 1;
    bottom: 0;
  }
`
