import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  name?: string
}

export const Service = memo(function Service({ icon, name }: Props) {
  return (
    <Container dial={2}>
      {icon ? (
        <IconWrap dial={5}>
          <Icon src={icon} alt={name} width="40" height="36" />
        </IconWrap>
      ) : null}
      {name ? <Title>{name}</Title> : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 50%;
  margin-top: 3.5625rem;
  padding: 0 0.75rem;

  @media (max-width: 767px) {
    margin-top: 1.875rem;
  }
`

const Title = styled.label`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 0.875rem;
  margin-top: 1.3125rem;
  text-transform: uppercase;
`

const IconWrap = styled(FlexBox)`
  width: 5rem;
  height: 5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
`

const Icon = styled.img`
  width: 100%;
  max-width: 2.5rem;
  height: 2.25rem;
`
